import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { patchApi } from "../Utils/WebApi-utils"
import { GetProfile } from "../Utils/ReactQueries"
import { useMsal } from "@azure/msal-react"
import { useMutation, useQueryClient } from "react-query"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { underlineButton, down, saveButton, showMoreButton } from "./Buttons"
import {
  inputField,
  inputFieldError,
  inputFieldPhoneNumber,
  inputFieldPhoneNumberError,
} from "./InputField"
import "./styles.css"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { CountriesToValidate } from "../Utils/CountriesToValidate"
import { validatePhoneMiniUser } from "../Utils/Utils"
import LoadingModal from "../Modal/LoadingModal"
import { StringParam, useQueryParam } from "use-query-params"
import BankAccountModal from "../Modal/BankAccountModal"

/**
 * This component is for updating personal information about the user.
 *
 * @version 1.0.1
 * @visibleName Personal information
 * @author [Dylan Lesperance]
 * @author [Henrik Lie]
 */

export const UpdateProfile = ({}) => {
  const { instance, accounts, inProgress } = useMsal()
  const [seeProfile, setSeeProfile] = useState(false)
  const inputRef = useRef({})
  const userProfile = GetProfile(accounts, inProgress, instance)
  const [genderCode, setGenderCode] = useState(userProfile.data?.genderCode)
  const [validPhone, setValidPhone] = useState(false)
  const [tab, setTab] = useQueryParam("tab", StringParam)
  const [originalBankAccount, setOriginalBankAccount] = useState(
    userProfile?.data?.bankAccount == undefined
      ? ""
      : userProfile?.data?.bankAccount
  )
  const [newBankAccount, setNewBankAccount] = useState("")
  const [showModal, setShowModal] = useState(false)

  const [phoneValue, setPhoneValue] = useState(userProfile?.data?.mobilePhone)
  const queryClient = useQueryClient()
  const [token, setToken] = useState("")
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  })
  const [checkRequired, setCheckRequired] = useState(true)
  const [mobileDefaultValue, setMobileDefaultValue] = useState(null)
  const values = watch()

  const tzOffset = new Date().getTimezoneOffset() * 60000
  const localTime = new Date(Date.now() - tzOffset).toISOString()
  const today = localTime

  const UpdateUser = useMutation(
    async values =>
      patchApi(
        process.env.GATSBY_APP_WEBAPI + "HefContacts/me",
        values,
        accounts,
        inProgress,
        instance
      ).then(res => {
        res.data
      }),
    {
      onSuccess: (data, values) => {
        toast.success("Endringene ble lagret", {
          bodyClassName: "bg-blue text-white",
          theme: "colored",
          style: { backgroundColor: "#004C97" },
        })
        queryClient.invalidateQueries(["profile"])
      },
      onError: data => {
        toast.error("Noe gikk galt!")
      },
    }
  )

  const onSubmit = values => {
    setNewBankAccount(values?.bankAccount)
    values.mobilePhone = phoneValue

    if (
      (values.mobilePhone == undefined ||
        values.mobilePhone == "" ||
        values.mobilePhone.length <= 3) &&
      (values.email == undefined || values.email == "")
    ) {
      setValidPhone(false)
      setError("email", {
        message: "Ugyldig nummer",
      })
      setError("mobilePhone", {
        message: "Ugyldig nummer",
      })

      return toast.error("Skriv inn mobilnummer eller e-post")
    }
    if (validPhone === false) {
      return toast.error("Mobilnummer er ugyldig")
    }

    values.genderCode = parseInt(values.genderCode)

    if (values.mobilePhone == undefined || values.mobilePhone.length < 3) {
      values.mobilePhone = ""
    } else if (values.mobilePhone.length == 11) {
      values.mobilePhone = values.mobilePhone
    } else {
      values.mobilePhone = "+" + values.mobilePhone
    }
    if (values.mobilePhone) {
    }
    values.modifiedByCustomer = today

    // Show modal if user makes a change to bank account before saving
    if (originalBankAccount !== values.bankAccount) {
      setShowModal(true)
    } else {
      UpdateUser.mutate(values)
    }
  }

  const openUserInfo = async () => {
    setSeeProfile(!seeProfile)
    await userProfile.refetch()
  }

  const Gender = [
    { name: "Mann", value: 1 },
    { name: "Kvinne", value: 2 },
    { name: "Kjønnsskeiv", value: 778380001 },
    { name: "Ikke kjønnet", value: 778380000 },
    { name: "Ikke-binær", value: 778380002 },
  ]

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
    } else if (validPhone || userProfile?.data?.email?.length > 0) {
      setCheckRequired(false)
    }
  }, [inputRef.current["email"]])

  useEffect(() => {
    if (
      userProfile.data?.email?.length == 0 &&
      userProfile.data?.mobilePhone?.length == 0
    ) {
      setCheckRequired(true)
    }
  }, [inputRef.current["email"]])

  useEffect(() => {
    console.log(mobileDefaultValue)
  }, [mobileDefaultValue])

  useEffect(() => {
    if (
      mobileDefaultValue == null &&
      userProfile?.data?.mobilePhone != undefined
    ) {
      setMobileDefaultValue(userProfile?.data?.mobilePhone)
      setPhoneValue(userProfile?.data?.mobilePhone)
    }
  }, [userProfile?.data?.mobilePhone])

  useEffect(() => {
    if (tab === "userinfo") {
      setSeeProfile(true)
    }
  }, [tab])

  return (
    <div className="relative" id="Personopplysninger">
      <BankAccountModal
        open={showModal}
        setOpen={setShowModal}
        bankAccount={newBankAccount}
        values={values}
        UpdateUser={UpdateUser}
      />
      <button
        className={showMoreButton + "border-t-2 border-blue"}
        onClick={openUserInfo}
      >
        {seeProfile ? (
          <h3 className="text-base font-sans font-semibold mb-0 ">
            Personopplysninger
          </h3>
        ) : (
          <h3 className="text-base font-sans font-normal mb-0 ">
            Personopplysninger
          </h3>
        )}
        {seeProfile ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all rotate-180"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-xl font-bold font-serif transition-all"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        )}
      </button>
      {/* Profile */}
      {seeProfile ? (
        <div className=" transition-all ">
          <form name="contact" method="patch" onSubmit={handleSubmit(onSubmit)}>
            {UpdateUser.isLoading ? (
              <LoadingModal />
            ) : (
              <div className="flex flex-col space-y-7 justify-between border-blue">
                <div>
                  {userProfile?.data?.socialSecurityNumber ==
                  undefined ? null : (
                    <p className="font-sans text-base mb-0">
                      Ditt fødselsnummer er{" "}
                      {userProfile?.data?.socialSecurityNumber}
                    </p>
                  )}
                </div>
                {/* First name */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Fornavn
                  </label>
                  <div
                    className="space-y-3 relative"
                    key={userProfile.data?.firstName}
                  >
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Fornavn"
                      defaultValue={userProfile.data?.firstName}
                      {...register("firstName")}
                      //onChange={e => (patchObj.firstName = e.target.value)}
                    ></input>
                  </div>
                </div>
                {/* Last name */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Etternavn
                  </label>
                  <div>
                    <input
                      type="string"
                      className={errors.lastName ? inputFieldError : inputField}
                      placeholder="Etternavn"
                      defaultValue={userProfile.data?.lastName}
                      required
                      {...register("lastName", { required: true })}
                    ></input>
                  </div>
                </div>
                {/* Email */}
                <div>
                  <label className="text-base text-blue font-sans">
                    E-post
                  </label>
                  <div className="relative">
                    {errors.email && (
                      <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible">
                        Ugyldig e-post
                      </label>
                    )}
                    <input
                      type="Email"
                      ref={value => (inputRef.current["email"] = value)}
                      id="floatField"
                      className={errors.email ? inputFieldError : inputField}
                      placeholder="E-post"
                      defaultValue={userProfile.data?.email}
                      {...register("email", {
                        pattern:
                          /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      })}
                    ></input>
                  </div>
                  {errors.email && (
                    <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                      Ugyldig e-post
                    </label>
                  )}
                </div>
                {/* Phonenumber */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Mobilnummer
                  </label>
                  <div className="relative">
                    <div
                      className={
                        errors.mobilePhone
                          ? inputFieldPhoneNumberError
                          : inputFieldPhoneNumber
                      }
                    >
                      <Controller
                        name="mobilePhone"
                        control={control}
                        defaultValue={mobileDefaultValue}
                        render={({ field: { onChange, value, name } }) => (
                          <PhoneInput
                            autoFormat={false}
                            // containerStyle={{
                            //   backgroundColor: "black !important",
                            //   border: "10px !important",
                            // }}
                            inputStyle={{
                              maxHeight: "52px",
                              width: "100%",
                              border: "0px",
                              borderRadius: "0px",
                              boxShadow: "0px",
                              color: "black",
                              fontSize: "18px",
                            }}
                            country={"no"}
                            inputProps={{
                              name: name,
                            }}
                            onlyCountries={CountriesToValidate}
                            value={phoneValue}
                            onChange={e => setPhoneValue(e)}
                            isValid={inputNumber =>
                              validatePhoneMiniUser(inputNumber, setValidPhone)
                            }
                          />
                        )}
                      />
                      {(errors.mobilePhone || validPhone === false) && (
                        <label className="font-sans text-orange-dark flex items-center absolute  inset-y-0 right-0 p-2 pr-4 invisible sm:visible">
                          Ugyldig telefonnummer
                        </label>
                      )}
                    </div>
                    {(errors.mobilePhone || validPhone === false) && (
                      <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                        Ugyldig telefonnummer
                      </label>
                    )}
                  </div>
                </div>
                {/* Address */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Adresse (valgfritt)
                  </label>
                  <div>
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Adresse"
                      defaultValue={userProfile.data?.addressLine1}
                      {...register("addressLine1")}
                    ></input>
                  </div>
                </div>
                {/* Postal code */}
                <div>
                  <label className="text-base text-blue font-sans">
                    Postnummer
                  </label>
                  <div className="relative">
                    {errors.postalCode && (
                      <label className="font-sans text-orange-dark mt-3 absolute inset-y-0 right-0 p-2 pr-4 invisible sm:visible ">
                        Ugyldig postnummer
                      </label>
                    )}
                    <input
                      type="text"
                      inputMode="numeric"
                      maxLength={4}
                      required
                      className={
                        errors.postalCode ? inputFieldError : inputField
                      }
                      placeholder="Postnummer"
                      defaultValue={userProfile.data?.postalCode}
                      {...register("postalCode", {
                        maxLength: 4,
                        minLength: 4,
                        required: true,
                      })}
                    ></input>
                  </div>
                  {errors.postalCode && (
                    <label className="font-sans text-orange-dark p-2 pr-4 justify-end visible flex sm:hidden">
                      Ugyldig postnummer
                    </label>
                  )}
                </div>
                {/* BankAccount */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Kontonummer
                  </label>
                  <div>
                    <input
                      type="text"
                      inputMode="numeric"
                      maxLength={11}
                      minLength={11}
                      required={originalBankAccount !== ""}
                      className={
                        errors.bankAccount ? inputFieldError : inputField
                      }
                      placeholder="Kontonummer"
                      defaultValue={
                        userProfile.data?.bankAccount == undefined
                          ? ""
                          : userProfile.data?.bankAccount
                      }
                      {...register("bankAccount", {
                        pattern: /^[0-9]{11}$/,
                        validate: value =>
                          originalBankAccount === "" || value !== "",
                      })}
                    ></input>
                  </div>
                  {errors.bankAccount && originalBankAccount !== "" && (
                    <p className="font-sans text-orange-dark pt-2">
                      Kontonummer kan ikke fjernes - bare endres - da det brukes
                      til utbetalinger ved reiseregninger, honorarer, og annet.
                      Ta kontakt på human@human.no om du har spørsmål.
                    </p>
                  )}
                </div>
                {/* Gender */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Kjønn (valgfritt)
                  </label>
                  <div className="relative mt-2">
                    <select
                      {...register("genderCode")}
                      defaultValue={userProfile?.data?.genderCode}
                      className="font-sans text-sm text-black border-b-2 bg-white border-blue p-2 mt-2 w-full h-12 hover:border-2 focus:ring-2 focus:ring-blue focus:border-0 focus:outline-none"
                    >
                      {userProfile?.data?.genderCode == undefined ? (
                        <option
                          key="0"
                          value=""
                          style={{ display: "none" }}
                          selected
                        >
                          Ikke valgt
                        </option>
                      ) : null}
                      {Gender.map(option => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Pronoun */}
                <div className="">
                  <label className="text-base text-blue font-sans">
                    Pronomen (valgfritt)
                  </label>
                  <div>
                    <input
                      type="string"
                      className={inputField}
                      placeholder="Pronomen"
                      defaultValue={userProfile.data?.pronoun}
                      {...register("pronoun")}
                      //onChange={e => {(patchObj.pronoun = e.target.value)}}
                    ></input>
                  </div>
                </div>
                <div>
                  {/* Buttons */}
                  <button type="submit" className={saveButton + down}>
                    Lagre endringer
                  </button>
                  <button
                    type="reset"
                    onClick={() => {
                      setPhoneValue(userProfile?.data?.mobilePhone)
                      queryClient.invalidateQueries(["profile"])
                    }}
                    className={underlineButton}
                  >
                    Forkast endringer
                  </button>
                </div>
                <div>
                  {/* Validation errors */}
                  {(errors.lastName ||
                    errors.email ||
                    errors.mobilePhone ||
                    errors.postalCode ||
                    errors.bankAccount) && (
                    <label className="font-sans text-orange-dark mt-2">
                      Du har glemt å fylle inn noen felter
                    </label>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      ) : null}
      {/* Accordion borders */}
      {seeProfile ? (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:-translate-y-1"></div>
      ) : (
        <div className="border-b-2 border-blue transform transition-transform peer-hover:translate-y-1"></div>
      )}
    </div>
  )
}
