import React, { useState, useEffect } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { GetProfile } from "../Utils/ReactQueries"
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react"

import { loginRequest } from "../Security/authConfig"
import { ErrorComponent } from "../Utils/ErrorComponent"
import { UpdateProfile } from "../Styling/UpdateProfileForm"
import { ConfirmUserInfo } from "../Modal/ConfirmModal"
import moment from "moment"
import { MySubscriptions } from "./MySubscriptions"
import MyPosts from "./MyPosts"
import "react-toastify/dist/ReactToastify.css"
import { MyDocuments } from "./MyDocuments"
import MyComAndMarketing from "./MyCommunicationAndMarketing"
import LoadingModal from "../Modal/LoadingModal"
import Seo from "../../components/seo"
import CheckMembershipStatus from "./CheckMembershipStatus"
import RepresentativeButton from "./RepresentativeButton"
import Notifications from "../Styling/Notifications"
import { MyCases } from "./MyCases"
import { Link } from "gatsby"
import { right } from "../Styling/Buttons"
import { InteractionType, RedirectRequest } from "@azure/msal-browser"
import MyActivities from "./MyActivities"

const queryClient = new QueryClient()

export const UserProfileContent = () => {
  const { instance, accounts, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const [infoConfirmed, setInfoConfirmed] = useState(false)
  const [loading, setLoading] = useState(true)

  const myProfile = GetProfile(accounts, inProgress, instance)

  const dateNow = new Date()

  const modified = myProfile?.data?.modifiedByCustomer

  const dateChecker = moment
    .utc(dateNow.setMonth(dateNow.getMonth() - 6))
    .toISOString()

  useEffect(() => {
    if (
      myProfile.isSuccess &&
      myProfile?.data != null &&
      myProfile.isRefetching == false
    ) {
      setLoading(false)
      if (modified == undefined || modified < dateChecker) {
        console.log(true)
        setInfoConfirmed(true)
      } else {
        console.log(false)
        setInfoConfirmed(false)
      }
    } else if (myProfile.isSuccess && myProfile?.data == null) {
      myProfile.refetch()
      setLoading(true)
    }
  }, [myProfile.isSuccess, myProfile.isRefetching])

  const isLicensedUser: boolean = myProfile?.data?.isLicensedEntraUser

  return (
    <div>
      {loading ? (
        <LoadingModal />
      ) : (
        <div>
          <div className="bg-gray px-8 py-8 pb-16  lg:flex lg:justify-center lg:items-center">
            <div className="lg:min-w-[50%] lg:max-w-[50%]">
              <Seo title="Minside" />
              {infoConfirmed ? (
                <ConfirmUserInfo setInfoConfirmed={setInfoConfirmed} />
              ) : null}
              <div className="mb-8 flex flex-col">
                <h1 className="font-serif text-blue mb-4 text-xl font-normal ">
                  Hei, {myProfile?.data?.firstName}! <br /> Dette er din side.
                </h1>
                <CheckMembershipStatus />
                <RepresentativeButton isLicensedEntraUser={isLicensedUser} />
              </div>
              <div>
                <Notifications />
              </div>
              <div>
                <MyActivities />
              </div>
              <div className="mt-12 flow-root">
                <div className="mb-4">
                  <h2
                    className="font-serif font-semibold text-lg text-blue mb-0"
                    id="MineInnstillinger"
                  >
                    Mine innstillinger
                  </h2>
                </div>
                <div>
                  <div>
                    <div>
                      <UpdateProfile />
                      <MyCases />
                      <MyComAndMarketing />
                      <MySubscriptions />
                      <MyPosts />
                      <MyDocuments />
                      <Link
                        className={
                          " fixed bottom-5 right-8 lg:right-32 bg-gray border-2 border-blue p-1 px-3 font-sans text-blue hover:text-white hover:border-white hover:bg-100 transition-all duration-100 bg-no-repeat to-blue from-blue bg-gradient-to-t " +
                          right
                        }
                        to="/ny-henvendelse"
                      >
                        Kontakt oss
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const UserInfo = () => {
  const authRequest: RedirectRequest = {
    ...loginRequest,
  }

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      // loadingComponent={LoadingModal}
    >
      <QueryClientProvider client={queryClient}>
        <UserProfileContent />
      </QueryClientProvider>
    </MsalAuthenticationTemplate>
  )
}

export default UserInfo
