import { useMsal } from "@azure/msal-react"
import React from "react"
import { Link } from "gatsby"
import { GetPortalSecurity } from "../Utils/ReactQueries"
import { GoToPageButton, right } from "../Styling/Buttons"

export type RepresentativeButtonTypes = {
  isLicensedEntraUser: boolean
}

const representativeButton = (props: RepresentativeButtonTypes) => {
  const { instance, accounts, inProgress } = useMsal()

  const checkPortalSecurity = GetPortalSecurity(accounts, inProgress, instance)

  return (
    <div>
      {checkPortalSecurity?.data?.[0]?.portalSecurityRole == "292460001" ||
      props.isLicensedEntraUser == true ? (
        <div className=" pt-2 mb-0">
          <Link to="/tillitsvalgt" className={GoToPageButton + right}>
            Gå til Tillitsvalgtportalen
          </Link>
        </div>
      ) : null}
    </div>
  )
}

export default representativeButton
